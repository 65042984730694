import { action, extendObservable, reaction, runInAction } from "mobx";

import { hasIntersection } from "@dvsproj/ipat-core/areaUtils";
import { sleep } from "@dvsproj/ipat-core/helpers";
import { lineLength } from "@dvsproj/ipat-core/geometryUtils";

import { sizeInMetersByPixel } from "@dvsproj/ipat-core/planUtils";
import {
  bomHasDeprecatedElements,
  getAllBomList,
  groupBomByBomId,
} from "../../utils/bomUtils";
import { getExtremePointsFromPoints } from "../../utils/uiUtils";
import initSmartLook from "../../smartlook";
import { PRESSURE_TUBING_COLOR } from "../types/planPipelineFactory";
import {
  driplineKits,
  pipesAndFittingsForIrrigators,
  raisedBedKits,
  rzwsKits,
  sprinklerKits,
} from "../../utils/bom-utils/irrigators";
import { controllerKits } from "../../utils/bom-utils/controller-utils";
import { valveBoxAndCables } from "../../utils/bom-utils/valve-box-utils";
import { fertilizerSpreader } from "../../utils/bom-utils/fertilizer-spreader-utils";
import { airCompressor } from "../../utils/bom-utils/compressor-utils";
import {
  cableForWaterSupplySensors,
  combiBox,
  waterFilter,
  waterMeter,
  waterSupply,
} from "../../utils/bom-utils/water-supply-utils";
import { pipesAndFittingsForPressureTubing } from "../../utils/bom-utils/pressure-tubing-utils";
import { sensorKits } from "../../utils/bom-utils/sensor-utils";
import { planVersion as PLAN_VERSION } from "../planFactory/planVersion";
import { semverCompare } from "../../utils/string-utils";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state, settingsState, calcApi, restApi) => {
  const stepLabels = settingsState ? settingsState.texts.steps : null;
  const confirmLabels = settingsState ? settingsState.dialog : null;

  extendObservable(state, {
    calculatingTitle: null,
    setCalculatingTitle: action((value) => {
      state.calculatingTitle = value;
    }),
    stepIdx: 0,
    get step() {
      if (
        state.stepIdx === 0 &&
        state.stepOrder[state.stepIdx].calculationNeeded
      ) {
        state.stepOrder[state.stepIdx].calculate();
      }
      return state.stepOrder[state.stepIdx];
    },
    stepIdxByName(name) {
      return state.stepOrder.findIndex((step) => step.name === name);
    },
    get stepOrder() {
      return [
        {
          name: "upload",
          validateStep: () => {
            let errors = [];
            if (this.plan == null) {
              errors.push(stepLabels ? stepLabels.upload.error.empty : "Error");
            }
            if (!state.user?.auth) {
              errors.push(
                stepLabels ? stepLabels.upload.error.userNotAuth : "Error"
              );
            }
            return errors.length > 0
              ? { title: stepLabels.errorTitle, errors }
              : {};
          },
          get calculationNeeded() {
            return !state.user?.auth;
          },
          calculate: async () => {
            let method = "step0";
            try {
              action(() => {
                state.calculatingTitle = state.settingsState
                  ? state.settingsState.texts.steps.upload.calculationTitle
                  : "calculation";
              })();
              await sleep(0);

              if (!state.user.auth) {
                method = "userinfo";
                await state.user.fetch();
              }

              let plan = null;

              if (state.user.auth) {
                method = "getPlan";
                plan = await state.getPlanInfo();
              }
              method = method != null ? "step0.action" : null;
              action(async () => {
                state.calculatingTitle = null;

                if (state.user.auth && plan) {
                  state.plan = plan;

                  const planPosition = state.getPlanPosition();

                  if (planPosition?.step) {
                    await state.toStep(planPosition.step);
                  } else {
                    await state.toMaxCalculatedStep();
                  }

                  state.toCenter();
                }
              })();
            } catch (e) {
              console.error(e);
              action(() => {
                state.calculatingTitle = null;
              })();
              state.showRestError(method, e);
            }
          },
        },
        {
          name: "areas",
          title: stepLabels ? stepLabels.areas.title : "areas",
          tooltip: stepLabels ? stepLabels.areas.tooltip : "areas",
          validateStep: () => {
            const { areas, scale } = state.plan ?? {};

            let errors = [];
            try {
              if (state.plan == null) {
                errors.push(
                  stepLabels ? stepLabels.upload.error.empty : "Error"
                );
              } else if (!state.planSizeValidatation()) {
                errors.push(stepLabels ? stepLabels.planSizeError : "Error");
              } else if (areas != null && areas.length > 0 && scale != null) {
                let hasIntersections = false;
                let hasSelfIntersections = false;
                areas.forEach((area) => {
                  if (!hasSelfIntersections && area.hasSelfIntersection) {
                    hasSelfIntersections = true;
                    errors.push(
                      stepLabels
                        ? stepLabels.areas.error.selfIntersecting
                        : "Error"
                    );
                  }
                  if (!hasIntersections && area.hasIntersection) {
                    hasIntersections = true;
                    errors.push(
                      stepLabels ? stepLabels.areas.error.intersecting : "Error"
                    );
                  }
                });
              } else {
                errors.push(
                  stepLabels ? stepLabels.areas.error.empty : "Error"
                );
              }
            } catch (e) {
              console.error(e);
            }

            return errors.length > 0
              ? { title: stepLabels.errorTitle, errors }
              : {};
          },
        },
        {
          name: "system-elements",
          title: stepLabels
            ? stepLabels.systemElements.title
            : "system-elements",
          tooltip: stepLabels ? stepLabels.systemElements.tooltip : "tooltip",
          validateStep: () => {
            const errors = [];
            try {
              if (state.plan == null) {
                errors.push(
                  stepLabels ? stepLabels.upload.error.empty : "Error"
                );
              } else if (!state.planSizeValidatation()) {
                errors.push(stepLabels ? stepLabels.planSizeError : "Error");
              } else {
                const { systemElements, areas } = state.plan;
                const waterSupply =
                  state.plan.getSystemElementsByType("water-supply")[0];
                const hasAllMandatoryElems = [
                  "water-supply",
                  "controller",
                  "valve-box",
                ].every((v) =>
                  systemElements?.map((se) => se.systemType).includes(v)
                );
                if (!hasAllMandatoryElems) {
                  errors.push(
                    stepLabels ? stepLabels.systemElements.error.empty : "Error"
                  );
                }

                if (waterSupply?.hasShapeLimitError) {
                  const { emptyWaterVolume, driplineWaterConsumption } =
                    stepLabels.systemElements?.error ?? {};

                  errors.push(
                    waterSupply.waterQuantity <= 0
                      ? emptyWaterVolume
                      : driplineWaterConsumption
                  );
                }

                const noIntersectionAreas = areas.filter(
                  (area) => !area.crossability
                );

                let intersectionRes = false;
                for (const systemELement of systemElements) {
                  intersectionRes =
                    intersectionRes ||
                    hasIntersection(
                      {
                        id: systemELement.id,
                        points: [{ x: systemELement.x, y: systemELement.y }],
                      },
                      noIntersectionAreas
                    );
                }
                if (intersectionRes) {
                  errors.push(
                    stepLabels
                      ? stepLabels.systemElements.error.noCrossabillityAreas
                      : "Error"
                  );
                }
              }
            } catch (e) {
              console.error(e);
            }
            return errors.length > 0
              ? { title: stepLabels.errorTitle, errors }
              : {};
          },
        },
        {
          name: "sprinklers",
          title: stepLabels ? stepLabels.sprinklers.title : "sprinklers",
          tooltip: stepLabels ? stepLabels.sprinklers.tooltip : "sprinklers",
          validateStep: () => {
            const { sprinklers, rzws, raisedBeds } = state.plan ?? {};
            let errors = [];

            try {
              if (state.plan == null) {
                errors.push(
                  stepLabels ? stepLabels.upload.error.empty : "Error"
                );
              } else if (!state.planSizeValidatation()) {
                errors.push(stepLabels ? stepLabels.planSizeError : "Error");
              } else if (
                sprinklers.length > 0 &&
                sprinklers.filter(({ hasInvalid }) => hasInvalid).length > 0
              ) {
                errors.push(
                  stepLabels ? stepLabels.sprinklers.error.invalid : "Error"
                );
              } else if (
                (sprinklers?.length ?? 0) +
                (rzws?.length ?? 0) +
                (raisedBeds?.length ?? 0) ===
                0
              ) {
                errors.push(
                  stepLabels ? stepLabels.sprinklers.error.empty : "Error"
                );
              }
            } catch (e) {
              console.error(e);
            }
            return errors.length > 0
              ? { title: stepLabels.errorTitle, errors }
              : {};
          },
          get calculationNeeded() {
            return (
              state.plan.sprinklersNeedToRecalculate ||
              (state.plan?.areas.length > 0 &&
                state.plan?.sprinklers.length === 0)
            );
          },
          calculate: async () => {
            await state.calculateSprinklers();
          },
        },
        {
          name: "pipeline",
          title: stepLabels ? stepLabels.pipeline.title : "pipeline",
          tooltip: stepLabels ? stepLabels.pipeline.tooltip : "pipeline",
          validateStep: () => {
            const { pipelines } = state.plan ?? {};

            let errors = [];

            try {
              if (state.plan == null) {
                errors.push(
                  stepLabels ? stepLabels.upload.error.empty : "Error"
                );
              } else if (!state.planSizeValidatation()) {
                errors.push(stepLabels ? stepLabels.planSizeError : "Error");
              } else if (pipelines != null && pipelines.length > 0) {
                const pipelineErrors = pipelines
                  .filter((p) => p.errors.length > 0)
                  .reduce((acc, p) => {
                    if (acc[p.color] == null) acc[p.color] = [];
                    acc[p.color].push(...p.errors);

                    return acc;
                  }, {});

                errors.push(
                  ...Object.entries(pipelineErrors).map(
                    ([color, messages]) => ({
                      color,
                      messages: messages.reduce(
                        (acc, m) => (acc.includes(m) ? acc : acc.concat([m])),
                        []
                      ),
                    })
                  )
                );

                const numberOfPressureTubing = pipelines.filter(
                  (p) => p.lineType === "pressure-tubing" && p.hasStartPoint
                ).length;

                // there should be only one pressure tubing
                if (numberOfPressureTubing > 1) {
                  errors.push({
                    color: PRESSURE_TUBING_COLOR,
                    messages: [
                      stepLabels
                        ? stepLabels.pipeline.error.onlyOnePressureTubing
                        : "Error",
                    ],
                  });
                } else if (numberOfPressureTubing === 0) {
                  errors.push({
                    color: PRESSURE_TUBING_COLOR,
                    messages: [
                      stepLabels
                        ? stepLabels.pipeline.error.requiredPressureTubing
                        : "Error",
                    ],
                  });
                }
              } else if (pipelines == null || pipelines.length === 0) {
                errors.push(
                  stepLabels ? stepLabels.pipeline.error.empty : "Error"
                );
              }

              const waterTaps = state.plan.getElementsByType("water-tap-point");
              if (waterTaps.length === 0) {
                const waterTapPipelines = pipelines?.filter(
                  (p) => p.hasWaterTapPoint
                );

                if (
                  waterTapPipelines?.some(
                    (p) => p.isTubingPipe && !p.hasStartPoint
                  )
                ) {
                  errors.push({
                    color: PRESSURE_TUBING_COLOR,
                    messages: [
                      stepLabels
                        ? stepLabels.pipeline.error.manualOfftakeNotConnected
                        : "Manual offtake error",
                    ],
                  });
                }
              }
            } catch (e) {
              console.error(e);
            }

            return errors.length > 0
              ? { title: stepLabels.pipeline.errorTitle, errors }
              : {};
          },
          get calculationNeeded() {
            try {
              if (
                state.plan === null ||
                state.plan.pipesNeedToRecalculate ||
                ["pipe", "pipeline-point"].every(
                  (type) => state.plan.getElementsByType(type).length === 0
                ) ||
                state.plan.pipelines?.find(
                  ({ lineType }) => lineType === "pressure-tubing"
                ) == null
              ) {
                return true;
              }
            } catch (e) {
              console.error(e);
            }

            return false;
          },
          calculate: async () => {
            await state.calculatePipelines();
          },
        },
        {
          name: "sensor",
          title: stepLabels ? stepLabels.sensor.title : "sensor",
          tooltip: stepLabels ? stepLabels.sensor.tooltip : "sensor",
          validateStep: () => {
            const isAllSensorsValid = (state.plan?.sensors ?? []).reduce(
              (pv, cv) => {
                return pv && !cv.isOnInvalidArea;
              },
              true
            );

            return !isAllSensorsValid && stepLabels != null
              ? {
                warnings: [stepLabels.sensor.warning],
              }
              : {};
          },
          get calculationNeeded() {
            return false;
          },
          calculate: async () => {
            try {
              action(() => {
                state.plan.sensorModified = false;
                state.savePlan();
              })();
            } catch (e) {
              console.error(e);
              const description = confirmLabels?.calculationServerError;
              state.showRestError("SENSOR", e, description);
            }
          },
        },
        {
          name: "recommendations",
          title: stepLabels ? stepLabels.bom.title : "recommendations",
          tooltip: stepLabels ? stepLabels.bom.tooltip : "recommendations",
          validateStep: () => {
            let errors = [];
            if (state.plan == null) {
              errors.push(stepLabels ? stepLabels.upload.error.empty : "Error");
            } else if (state.plan.bomItems.length === 0) {
              errors.push(stepLabels ? stepLabels.bom.error.empty : "Error");
            }

            return errors.length > 0
              ? { title: stepLabels.errorTitle, errors }
              : {};
          },
          get calculationNeeded() {
            return (
              state.plan.bomItems.length === 0 ||
              semverCompare(state.plan.version, PLAN_VERSION) < 0 ||
              !state.plan.bomType ||
              bomHasDeprecatedElements(state.plan.bomItems, settingsState)
            );
          },
          calculate: async () => {
            const { plan } = state;
            action(() => {
              state.calculatingTitle = stepLabels
                ? stepLabels.bom.calculationTitle
                : "Calculation";
            })();

            if (plan.bomItems.length) {
              if (semverCompare(plan.version, PLAN_VERSION) < 0
                || bomHasDeprecatedElements(plan.bomItems, settingsState)
              ) {
                const shouldRecalculateBom = await state.showPopup({
                  id: "recalc_bom",
                });

                if (!shouldRecalculateBom) {
                  runInAction(() => {
                    // TODO: count max bom type
                    // we do not applyBomType, we just update the flag
                    // on the plan, so this check is not performed anymore
                    if (plan.bomType == null) plan.bomType = "expert";
                  });
                  return;
                }
              }
            }

            await sleep(0);
            try {
              if (
                plan.elements.filter(
                  (e) =>
                    e.type === "irrigationValveCable" ||
                    e.type === "waterMeterCable"
                ).length !== 2
              ) {
                await plan.recalculateCables(state.planId, calcApi, undefined, [
                  "irrigationValveCable",
                  "waterMeterCable",
                ]);
              }

              const bomItems = groupBomByBomId([
                ...getAllBomList(settingsState),
                ...sprinklerKits(settingsState, plan),
                ...driplineKits(settingsState, plan),
                ...rzwsKits(settingsState, plan),
                ...raisedBedKits(settingsState, plan),

                ...pipesAndFittingsForIrrigators(settingsState, plan),

                ...controllerKits(settingsState, plan),
                ...valveBoxAndCables(settingsState, plan),
                ...waterSupply(settingsState, plan),

                ...fertilizerSpreader(settingsState, plan),
                ...airCompressor(settingsState, plan),
                ...waterFilter(settingsState, plan),
                ...waterMeter(settingsState, plan),
                ...combiBox(settingsState, plan),
                ...cableForWaterSupplySensors(settingsState, plan),
                ...sensorKits(settingsState, plan),

                ...pipesAndFittingsForPressureTubing(settingsState, plan),
              ]);

              action(() => {
                state.plan.addBomItemsToStorage(bomItems);
                state.plan.bomModified = false;
                state.savePlan();
              })();
            } catch (e) {
              console.error(e);
              const description = confirmLabels?.calculationServerError;
              state.showRestError("BOM", e, description);
            }
            action(() => {
              state.canFetchPrice = true;
              state.calculatingTitle = null;
            })();
          },
        },
      ];
    },
    toMaxCalculatedStep: () => {
      const { maxCalculatedStep } = state;
      if (maxCalculatedStep > state.stepIdx) {
        state.toStep(maxCalculatedStep);
      }
    },
    get maxCalculatedStep() {
      for (let i = 1; i < state.stepOrder.length; i++) {
        const { validateStep, calculationNeeded } = state.stepOrder[i];
        const validation = validateStep != null ? validateStep() : {};
        const { errors } = validation;

        const stepHasCalculated =
          calculationNeeded != null ? calculationNeeded : false;

        if (errors != null || stepHasCalculated) {
          return i - 1;
        }
      }
      return state.stepOrder.length - 1;
    },
    get maxPossibleStep() {
      let i = 0;
      while (i < state.stepOrder.length) {
        const { validateStep } = state.stepOrder[i];
        const validation = validateStep != null ? validateStep() : {};
        const { errors } = validation;

        if (errors != null) {
          return i;
        }

        i++;
      }

      return i >= state.stepOrder.length ? state.stepOrder.length - 1 : i;
    },
    get prevStep() {
      return state.stepIdx > 1 ? () => state.toStep(state.stepIdx - 1) : null;
    },
    get nextStep() {
      return state.stepIdx < state.maxPossibleStep
        ? () => state.toStep(state.stepIdx + 1)
        : null;
    },
    toStep: async (stepIdx) => {
      if (state.calculatingTitle != null) return false;

      action(() => {
        state.calculatingTitle = "Loading";
      })();
      let newStepIdx = stepIdx;

      try {
        if (state.stepIdx < stepIdx) {
          for (let i = state.stepIdx + 1; i <= stepIdx; i++) {
            const curStep = state.stepOrder[i - 1];
            if (curStep) {
              const validation = curStep.validateStep
                ? curStep.validateStep()
                : {};
              const { errors = [] } = validation;

              if (errors.length > 0) break;
            }
            newStepIdx = i;
            if (state.planIsEditable) {
              if (state.stepOrder[i].calculationNeeded) {
                await state.stepOrder[i].calculate();
              }
            }
          }
        }
        action(() => {
          if (state.stepIdx !== newStepIdx) state.stepIdx = newStepIdx;
          state.clearSelectedElement();
          state.setSelectedTool("select");
        })();
      } catch (e) {
        console.error("Error changing step", e);
      } finally {
        action(() => {
          state.calculatingTitle = null;
        })();
      }
    },
    toMaxPossibleStepIfNeeded: () => {
      const { maxPossibleStep } = state;

      if (maxPossibleStep <= state.stepIdx) {
        if (maxPossibleStep >= 2 && state.plan.sprinklersNeedToRecalculate) {
          action(() => {
            state.plan.sprinklersNeedToRecalculate = false;
          })();
        }
        if (maxPossibleStep >= 4 && state.plan.pipesNeedToRecalculate) {
          action(() => {
            state.plan.pipesNeedToRecalculate = false;
          })();
        }
        state.toStep(maxPossibleStep);
      }
    },
  });

  extendObservable(state, {
    get planSize() {
      const points = [
        ...state.plan.areas.reduce((acc, { extremePoints }) => {
          acc.push(...extremePoints);
          return acc;
        }, []),
        ...state.plan.sprinklers.reduce((acc, { extremePoints }) => {
          acc.push(...extremePoints);
          return acc;
        }, []),
        ...state.plan.systemElements.map((s) => ({ x: s.x, y: s.y })),
        ...state.plan.pipePoints.map((s) => ({ x: s.x, y: s.y })),
      ];

      const { minX, maxX, minY, maxY } = getExtremePointsFromPoints(points);

      if (minX && maxX && minY && maxY) {
        const width = sizeInMetersByPixel(
          lineLength({ x: minX, y: minY }, { x: maxX, y: minY }),
          state.plan.scale
        );
        const height = sizeInMetersByPixel(
          lineLength({ x: minX, y: minY }, { x: minX, y: maxY }),
          state.plan.scale
        );

        return {
          width,
          height,
        };
      }

      return undefined;
    },
    planSizeValidatation: () => {
      const { width, height } = state.planSize || {};
      const { maxPlanWidth, maxPlanHeight } = settingsState || {};

      if (!maxPlanWidth || !maxPlanHeight) return false;

      return width <= maxPlanWidth && height <= maxPlanHeight;
    },
  });

  reaction(
    () => state.stepIdx,
    (stepIdx, oldStepIdx) => {
      if (stepIdx > (state?.plan?.maxStep ?? 0)) {
        state.plan.setMaxStep(stepIdx);
      }

      if (stepIdx > oldStepIdx) {
        state.sendMarketingStatistics();
      }
    }
  );

  reaction(
    () => state.plan?.maxStep,
    (maxStepId) => {
      //FIXME please add check user consent
      if (maxStepId >= 0) {
        initSmartLook();
      }
    }
  );
};
