import { extendObservable, observable, reaction, toJS } from "mobx";
import { toFixedPrecision } from "../../utils/uiUtils";
const {
  sizeInMetersByPixel,
  getOffsetsAndSizeByElements,
} = require("@dvsproj/ipat-core/planUtils");

export const availableStatuses = {
  Created: "created",
  Finalize: "finalize",
  DuplicateToCheck: "wait for check",
  ToCheck: "to check",
  Checked: "checked",
  DuplicateChecked: "checked by duplicate",
  AreasAdded: "areas added",
  SprinklersCalculated: "sprinklers calculated",
  SprinklersModified: "sprinklers is modified",
  SystemElementsAdded: "system element is added",
  PipesCalcualted: "pipelines calculated",
  PipesModified: "pipelines is modified",
  SensorModified: "sensor modified",
  BomCalculated: "bom calculated",
  BomModified: "bom is modified",
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (plan, settingsState, intl) =>
  ({ offsetX, offsetY, unit, status = availableStatuses.Created }) => {
    const state = observable({
      status,
    });

    extendObservable(plan, {
      get toJSON() {
        const {
          sprinklerSetType,
          unit,
          scale,
          editable,
          opacity,
          bomItems,
          status,
          sprinklersModified,
          pipesModified,
          sprinklersNeedToRecalculate,
          pipesNeedToRecalculate,
          sensorModified,
          bomModified,
          showWaterSupplyVideo,
          showSensorPlan,
          background,
          sprinklersAlgoSettings,
          planningTime,
          isNotify,
          maxStep,
          version,
          pipelinesAlgo,
          bomType,
          irrigationTubeType,
          pressureTubeType,
          couponType,
        } = this;

        let result = {
          sprinklerSetType,
          scale,
          opacity,
          unit,
          editable,
          elements: plan.elements.map((e) => e.toJSON),
          bomItems: bomItems
            .filter((e) => e.quantity > 0 || e.defaultQuantity > 0)
            .map((e) => e.toJSON),
          status,
          sprinklersModified,
          pipesModified,
          sprinklersNeedToRecalculate,
          pipesNeedToRecalculate,
          sensorModified,
          bomModified,
          showWaterSupplyVideo,
          showSensorPlan,
          sprinklersAlgoSettings: toJS(sprinklersAlgoSettings),
          planningTime,
          isNotify,
          maxStep,
          version,
          pipelinesAlgo,
          bomType,
          irrigationTubeType,
          pressureTubeType,
          couponType,
        };

        if (background) {
          const { width, height } = background;
          result.background = {
            width,
            height,
          };
        }

        if (offsetX || offsetY) {
          result.offsetX = offsetX;
          result.offsetY = offsetY;
        }
        return result;
      },
      get toSaveJSON() {
        const sensorPipelineMap = plan.elements
          .filter((e) => e.type === "sensor")
          .map((e) => {
            return {
              id: e.id,
              pipeline: e.onArea?.id,
            };
          });
        let result = plan.toJSON;
        return {
          ...result,
          elements: result?.elements?.map((e) => {
            return e.type === "sensor"
              ? {
                ...e,
                pipeline: sensorPipelineMap?.find((el) => el.id === e.id)
                  ?.pipeline,
              }
              : e;
          }),
        };
      },
      get toStatsJSON() {
        const { scale, pipelinesAlgo } = this;

        const size = getOffsetsAndSizeByElements(plan?.elements ?? [], 0, 0);

        const sprinklers = plan?.elements?.filter(
          (e) =>
            e.type === "sprinkler" ||
            e.type === "rzws" ||
            e.type === "raised-bed"
        );
        const max_drop = sprinklers?.reduce((acc, sprinkler) => {
          return Math.max(acc, sprinkler.waterdropInBar ?? 0);
        }, 0);

        //pipes
        let circuitsCount = 0,
          pipelinesLength = 0,
          pressureTubingLength = 0,
          maxPipelineLength = 0;

        plan.pipelines.forEach((pipeline) => {
          switch (pipeline.lineType) {
            case "sprinkler":
            case "rzws-line":
            case "raised-bed-line":
              circuitsCount++;
              const pipeLength = sizeInMetersByPixel(
                pipeline.totalLength,
                scale
              );
              pipelinesLength += pipeLength;
              maxPipelineLength =
                maxPipelineLength > pipeLength ? maxPipelineLength : pipeLength;
              break;
            case "pressure-tubing":
              pressureTubingLength += sizeInMetersByPixel(
                pipeline.totalLength,
                scale
              );
              break;
            default:
              break;
          }
        });

        const result = {
          pipelines: {
            sprinklers_count: sprinklers?.length ?? 0,
            valvebox_count:
              plan?.elements?.filter(
                (e) =>
                  e.type === "system-element" && e.systemType === "valve-box"
              )?.length ?? 0,
            plan_area_size:
              toFixedPrecision(
                sizeInMetersByPixel(size.width, scale) *
                sizeInMetersByPixel(size.height, scale),
                2
              ) * 1,
            circuits_count: circuitsCount,
            pipelines_length: toFixedPrecision(pipelinesLength, 2) * 1,
            pipeline_avg_length:
              pipelinesLength && circuitsCount
                ? toFixedPrecision(pipelinesLength / circuitsCount, 2) * 1
                : null,
            longest_pipeline_sprinkler_valve:
              toFixedPrecision(maxPipelineLength + pressureTubingLength, 2) * 1,
            max_drop,
            pipes_algo: pipelinesAlgo,
          },
        };

        return result;
      },
      get unit() {
        return unit;
      },
      get offsetX() {
        return offsetX;
      },
      get offsetY() {
        return offsetY;
      },
      get editable() {
        return !(
          [
            availableStatuses.Finalize,
            availableStatuses.ToCheck,
            availableStatuses.DuplicateToCheck,
          ].indexOf(this.status) >= 0
        );
      },
      get status() {
        return state.status;
      },
      set status(status) {
        const isCheckStatus =
          [
            availableStatuses.ToCheck,
            availableStatuses.DuplicateToCheck,
          ].indexOf(state.status) >= 0;

        if (
          this.editable ||
          (isCheckStatus &&
            [
              availableStatuses.Checked,
              availableStatuses.DuplicateChecked,
            ].indexOf(status) >= 0)
        ) {
          state.status = status;
        }
      },
    });

    extendObservable(plan, {
      getStatusByCalculatedStep() {
        const elementsArray = [
          plan.areas,
          plan.sprinklers,
          plan.systemElements,
          plan.pipes,
          plan.bomItems,
        ];

        let calculatedStep = 0;
        for (const el of elementsArray) {
          if (el && el.length > 0) {
            calculatedStep++;
          } else {
            break;
          }
        }

        let status;
        switch (calculatedStep) {
          case 1:
            status = availableStatuses.AreasAdded;
            break;
          case 2:
            status = plan.sprinklersModified
              ? availableStatuses.SprinklersModified
              : availableStatuses.SprinklersCalculated;
            break;
          case 3:
            status =
              availableStatuses.SystemElementsAdded +
              " (count: " +
              plan.systemElements.length +
              ")";
            break;
          case 4:
            status = plan.pipesModified
              ? availableStatuses.PipesModified
              : availableStatuses.PipesCalcualted;
            break;
          case 5:
            status = availableStatuses.SensorModified;
            break;
          case 6:
            status = plan.bomModified
              ? availableStatuses.BomModified
              : availableStatuses.BomCalculated;
            break;
          default:
            status = availableStatuses.Created;
            break;
        }

        return status;
      },
      lock() {
        state.status = availableStatuses.Finalize;
      },
      unlock() {
        state.status = plan.getStatusByCalculatedStep();
      },
    });

    reaction(
      () => plan.sprinklersModified,
      (modified) => {
        if (modified) plan.status = availableStatuses.SprinklersModified;
      }
    );

    reaction(
      () => plan.pipesModified,
      (modified) => {
        if (modified) plan.status = availableStatuses.PipesModified;
      }
    );

    reaction(
      () => plan.sensorModified,
      (modified) => {
        if (modified) plan.status = availableStatuses.SensorModified;
      }
    );

    reaction(
      () => plan.bomModified,
      (modified) => {
        if (modified) plan.status = availableStatuses.BomModified;
      }
    );

    reaction(
      () => plan.elements.length + plan.bomItems.length,
      () => {
        const status = plan.getStatusByCalculatedStep();

        if (status) {
          plan.status = status;
        }
      }
    );
  };
